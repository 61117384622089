import './App.css';
import data from './data/roles.json';
import Board from 'react-trello'


function shuffle(array) {
  var currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

function dataChanged(newData) {
  console.log(newData)
}


function Role(props) {
  return (
  <div>
  <h1>{props.role}</h1>
  <ul>
    {props.responsibilities.map(resp => <li>{resp}</li>)}
  </ul>
  </div>
  )
}

function initBoardData() {
  const boardData = {
    lanes: [
      {
        id: 'backlog',
        title: 'Backlog',
        cards: shuffle(Object.keys(data).map(resp => data[resp]).flat()).map((el, index) => {return {id:index, title: `Card #${index + 1}`, description: el}})
      },
      {
        id: 'SM',
        title: 'Scrum Master',
        cards: []
      },{
        id: 'DEV',
        title: 'Developers',
        cards: []
      },{
        id: 'PO',
        title: 'Product Owner',
        cards: []
      }
  
    ]
  }

  function validateQuiz() {
    
  }

  return boardData
}

function App() {
  let boardData = initBoardData()

  return (
    <div className="App">
        <Board data={boardData} onDataChange={dataChanged} hideCardDeleteIcon={true}/>
        
    </div>

  );
  //{Object.keys(data).map((key) => <Role role={key} key={key} responsibilities={data[key]}/>)}
}

export default App;
